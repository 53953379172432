import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

interface VisitsFilterProps {
    visitsFilter: string | null
    setVisitsFilter: (value: string | null) => void
    visitsOperator: '==' | '>' | '<'
    setVisitsOperator: (value: '==' | '>' | '<') => void
    error: boolean,
    helperText: string | undefined
}

const VisitsFilter: React.FC<VisitsFilterProps> = ({
    visitsFilter,
    setVisitsFilter,
    visitsOperator,
    setVisitsOperator,
    error,
    helperText,
}) => {
    const handleVisitsFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (/^\d*$/.test(value)) {
            setVisitsFilter(value || null)
        }
    }

    const handleVisitsOperatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVisitsOperator(event.target.value as '==' | '>' | '<')
    }

    return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        label="Visitas"
                        value={visitsFilter == null ? '' : visitsFilter}
                        onChange={handleVisitsFilterChange}
                        placeholder="Ej: 10"
                        fullWidth
                        inputProps={{ maxLength: 4 }}
                        error={error}
                        helperText={helperText}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        select
                        label="Operador"
                        value={visitsOperator}
                        onChange={handleVisitsOperatorChange}
                        SelectProps={{
                            native: true,
                        }}
                        fullWidth
                    >
                        <option value="==">=</option>
                        <option value=">">&gt;</option>
                        <option value="<">&lt;</option>
                    </TextField>
                </Grid>
            </Grid>
        </>
    )
}

export default VisitsFilter
