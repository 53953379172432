import React from 'react'
import MaterialTable, { MaterialTableProps, Query } from 'material-table'

const buildPaginationQuery = <T extends object>(query: Query<T>) => {
  const { page, pageSize, search, orderBy, orderDirection } = query

  const pageQuery = `page=${page ?? 0}`
  const pageSizeQuery = `&pageSize=${pageSize ?? 10}`
  const searchQuery = search ? `&search=${search}` : ''
  const orderByQuery = orderBy ? `&orderBy=${String(orderBy.field)}` : ''
  const orderDirectionQuery = orderDirection ? `&orderDirection=${orderDirection}` : ''

  return `${pageQuery}${pageSizeQuery}${searchQuery}${orderByQuery}${orderDirectionQuery}`
}

const DefaultTable = <T extends object>(props: MaterialTableProps<T>) => {
  const { options, ...restProps } = props
  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
          },
          toolbar: {
            nRowsSelected: '{0} filas(s) seleccionadas',
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar',
          },
          header: {
            actions: 'Acciones',
          },
          body: {
            emptyDataSourceMessage: 'No hay nada todavía.',
            filterRow: {
              filterTooltip: 'Filtrar',
            },
          },
        }}
        options={{
          pageSize: 10,
          pageSizeOptions: [5, 10, 20, 50, 100],
          actionsColumnIndex: restProps.columns.length,
          ...options,
        }}
        {...restProps}
      />
    </div>
  )
}

export { DefaultTable, buildPaginationQuery }
