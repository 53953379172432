import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

interface RewardFilterProps {
    rewardId: string
    setRewardId: (id: string) => void
    invertedLogic: boolean
    setInvertedLogic: (value: boolean) => void
}

const RewardFilter: React.FC<RewardFilterProps> = ({
    rewardId,
    setRewardId,
    invertedLogic,
    setInvertedLogic,
}) => {
    const handleRewardIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRewardId(event.target.value)
    }

    const handleInvertedLogicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInvertedLogic(event.target.checked)
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="ID de Premio"
                    value={rewardId}
                    onChange={handleRewardIdChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={invertedLogic}
                            onChange={handleInvertedLogicChange}
                            color="primary"
                        />
                    }
                    label={'Usuarios que usaron el premio'}
                />
            </Grid>
        </Grid>
    )
}

export default RewardFilter
