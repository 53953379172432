import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    background: "#fff",
    marginTop: 20,
    marginBottom: 40,
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    borderRadius: '4px',
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary
      },
      title: {
        textAlign: "left",
        padding: theme.spacing(4),
        marginLeft: "2.4rem",
        marginTop: "1rem",
      },
      input: {
        width: "100%"
      },
      rewardType: {
        textAlign: "left",
        height: '10%',
        alignItems: 'center',
      },
      checkbox: {
        textAlign: "right",
      },
      iconSection: {
        paddingLeft: "2rem",
        textAlign: "left",
      },
      iconUploadContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      iconUploadPreviewContainer: {
        textAlign: 'center',
        border: '1px dashed #ccc',
        margin: '0 20px',
        height: '50px',
        width: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      iconPreview: { maxWidth: 30, maxHeight: '100%' },
})
)

export { useStyles }
