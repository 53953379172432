import React, { useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { Layout } from 'components'
import { UserContext } from 'contexts'

import { Users } from './Users'
import { Admins } from './Admins'
import { DrawerItems } from './DrawerItems'
import { Stores } from './Stores'
import { Memberships } from './Memberships'
import { Download } from './Downloads'
import { Notifications } from './Notifications'
import { Tags } from './Tags'
import { ManualRewards } from './ManualRewards'
import { Dashboard } from './Dashboard'
import { UserPolls } from './UserPoll'
import { AutomaticNotifications } from './AutomaticNotifications'
import { Prizes } from './Prizes'
import { Styles } from './Styles'
import { Coupons } from './Coupons'

const Main: React.FC = () => {
  const { admin } = useContext(UserContext)
  if (!admin) return <Redirect to="/login" />

  return (
    <Layout>
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/memberships" />} />
        <Route path="/users" component={Users} />
        <Route path="/admins" component={Admins} />
        <Route path="/drawer-items" component={DrawerItems} />
        <Route path="/stores" component={Stores} />
        <Route path="/coupons" component={Coupons} />
        <Route path="/memberships" component={Memberships} />
        <Route path="/rewards-automatizados" component={Prizes} />
        <Route path="/rewards-manuales" exact component={ManualRewards} />
        <Route path="/downloads" component={Download} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/notificaciones/automaticas" component={AutomaticNotifications} />
        <Route path="/tags" exact component={Tags} />
        <Route path="/user-test" exact component={UserPolls} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/styles" component={Styles} />
        <Route component={() => <h1>404 no encontrado</h1>} />
      </Switch>
    </Layout>
  )
}

export { Main }
