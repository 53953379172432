import { Divider, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { getVersion } from "./api"
import { useApiCall } from "hooks"
import { makeStyles } from "@material-ui/core/styles"
import { COLORS } from "styles"

const useStyles = makeStyles(theme => ({
  versionContainer: {
    margin: theme.spacing(1, 0),
    textAlign: 'center',
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  version: {
    color: COLORS.GREY,
  },
}))

const Version: React.FC = () => {
  const classes = useStyles()
  const [requestData, isRequestingData] = useApiCall<void, { version: string }>(getVersion)
  const [version, setVersion] = useState<string | null>(null)

  const updateVersion = async () => {
    const response = await requestData()
    setVersion(response.version)
  }

  useEffect(() => {
    updateVersion()
  }, [])

  return (
    <div className={classes.versionContainer}>
      <Divider className={classes.divider} />
      <Typography variant="caption" className={classes.version}>v{version}</Typography>
    </div>
  )
}

export { Version }