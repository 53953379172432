import { AxiosRequestConfig } from 'axios'

import {
  UpdateDrawerItemApiData,
  CreateDrawerItemPayload,
  UpdateDrawerItemOrderPayload,
  UpdateDrawerItemSelectionPayload,
  UpdateDrawerItemEnabledPayload,
} from './types'

const getDrawerItems = (): AxiosRequestConfig => ({
  url: '/drawer-items',
  method: 'GET',
})

const createDrawerItem = (drawerItem: CreateDrawerItemPayload): AxiosRequestConfig => {
  const payload = new FormData()
  const { name, redirectUrl, logo } = drawerItem
  payload.append('name', name)
  payload.append('redirectUrl', redirectUrl)
  payload.append('logo', logo)

  return {
    url: '/drawer-items',
    method: 'POST',
    data: payload,
  }
}

const updateDrawerItem = (data: UpdateDrawerItemApiData): AxiosRequestConfig => {
  const payload = new FormData()
  const { name, redirectUrl, logo } = data.drawerItem
  if (name) payload.append('name', name)
  if (redirectUrl) payload.append('redirectUrl', redirectUrl)
  if (logo) payload.append('logo', logo)

  return {
    url: `/drawer-items/${data.id}`,
    method: 'PUT',
    data: payload,
  }
}

const updateDrawerItemsOrder = (data: UpdateDrawerItemOrderPayload[]): AxiosRequestConfig => ({
  url: `/drawer-items/order`,
  method: 'PUT',
  data: { drawerItems: data },
})

const updateDrawerItemsEnabled = (data: UpdateDrawerItemEnabledPayload[]): AxiosRequestConfig => ({
  url: `/drawer-items/enabled`,
  method: 'PUT',
  data: { drawerItems: data },
})

const updateDrawerItemsSelection = ({
  selected,
}: UpdateDrawerItemSelectionPayload): AxiosRequestConfig => ({
  url: `/drawer-items/selection`,
  method: 'PUT',
  data: { selected },
})

const deleteDrawerItem = (id: number): AxiosRequestConfig => ({
  url: `/drawer-items/${id}`,
  method: 'DELETE',
})

export {
  getDrawerItems,
  createDrawerItem,
  updateDrawerItem,
  updateDrawerItemsOrder,
  updateDrawerItemsSelection,
  updateDrawerItemsEnabled,
  deleteDrawerItem,
}
