import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { Select, MenuItem, InputLabel, InputAdornment, Typography, FormControlLabel, Checkbox, FormControl, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'

import { Dialog, UploadButton, snackbar } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

import { CreatePrizePayload, CreatePrizeResponse, Prize } from '../../../../Prizes/types'
import { createPrize } from '../../../../Prizes/api'
import { useStyles } from './styles'
import { REWARD_TYPE_BY_MEMBERSHIP } from '../constants';
import { PRIZE } from 'consts'
import { EditorInput } from 'features/Main/components'
import { StringUtils } from 'utils'
import { PrizeCategory } from 'consts/prize'

const rules = {
  title: [
    {
      validate: (title: string) => StringUtils.extractTextFromHTML(title).length <= 70,
      message: 'No puede superar los 70 caracteres',
    }
  ],
  shortTitle: [
    {
      validate: (shortTitle: string) => shortTitle.length <= 22,
      message: 'No puede superar los 22 caracteres',
    },
  ],
  additionalText: [
    {
      validate: (value: string) => (value && value.length <= 100) || !value.length,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  icon: [],
  expirationDays: [
    {
      validate: (value: string) =>
        ((value && Number(value) >= 1) || !value) && Number.isInteger(Number(value)),
      message: 'El vencimiento debe ser un número entero mayor a 0',
    },
  ],
  toteatBenefitId: [
    {
      validate: (value: string, data: any) => {
        const { toteatEnabled, category } = data
        if (toteatEnabled && category === PrizeCategory.Checkout) {
          return value.trim().length > 0
        }
        return true
      },
      message: 'El ID Beneficio Toteat es requerido',
      validateEmpty: true
    }
  ],
  skusList: [
    {
      validate: (skusList: string[], data: any) => {
        const { toteatEnabled, category } = data
        if (toteatEnabled && category === PrizeCategory.General) {
          return skusList.length > 0
        }
        return true
      },
      message: 'El SKU es requerido',
    }
  ]
}

interface CreateMembershipPrizeDialogProps {
  handleClose: () => void
  onDone: (prize: Prize) => void
  membershipId: number
}

const categories = Object.values(PrizeCategory)

const CreatePrizeDialog: React.FC<CreateMembershipPrizeDialogProps> = ({ handleClose, onDone, membershipId }) => {
  const classes = useStyles()

  const [toteatBenefitId, setToteatBenefitId] = useState<string>('')
  const [currentSku, setCurrentSku] = useState<string>('')
  const [skusList, setSkusList] = useState<string[]>([])
  const [storesEnabled, setStoresEnabled] = useState(true)
  const [tucanEnabled, setTucanEnabled] = useState(false)
  const [toteatEnabled, setToteatEnabled] = useState(false)
  const [category, setCategory] = useState<PrizeCategory | undefined>()
  const [title, setTitle] = useState('')
  const [shortTitle, setShortTitle] = useState('')
  const [icon, setIcon] = useState<File | null>(null)
  const [iconPreview, setIconPreview] = useState('')
  const [additionalText, setAdditionalText] = useState('')
  const [rewardType, setrewardType] = useState('')
  const [expirationDays, setExpirationDays] = useState<string>('30')

  const [createMembershipPrizeApi, isLoading] = useApiCall<CreatePrizePayload, CreatePrizeResponse>(
    createPrize,
  )

  const handleIconChange = (file: File) => {
    setIconPreview(URL.createObjectURL(file))
    setIcon(file)
  }

  const handlerewardTypeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setrewardType(event.target.value as string)
  }

  const handleToteatBenefitIdChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setToteatBenefitId(e.target.value)
  }

  const handleCurrentSkuChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setCurrentSku(e.target.value)
  }

  const handleAddSku = () => {
    if (currentSku.trim()) {
      setSkusList([...skusList, currentSku.trim()])
      setCurrentSku('')
    }
  }

  const handleRemoveSku = (skuToRemove: string) => {
    setSkusList(skusList.filter(sku => sku !== skuToRemove))
  }

  const requiredFields = [
    'title', 
    'shortTitle', 
    'additionalText', 
    'icon',
    ...rewardType === PRIZE.REWARD_TYPE.AUTOMATIC ? ['expirationDays'] : []
  ]

  const getRequiredFields = () => {
    const requiredFields = [
      'title',
      'shortTitle',
      'additionalText',
      'icon',
      ...rewardType === PRIZE.REWARD_TYPE.AUTOMATIC ? ['expirationDays'] : []
    ]

    if (toteatEnabled) {
      if (category === PrizeCategory.General) {
        requiredFields.push('skusList')
      } else if (category === PrizeCategory.Checkout) {
        requiredFields.push('toteatBenefitId')
      }
    }

    return requiredFields
  }

  const fields = { title, shortTitle, additionalText, icon, expirationDays, toteatEnabled, toteatBenefitId, skusList, category }
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(fields, rules, getRequiredFields())
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors

  const onCreateMembershipPrize = async () => {
    if (!icon) return
    const prize = {
      membershipId,
      title,
      shortTitle,
      icon,
      additionalText,
      rewardType,
      expirationDays,
      category,
      storesEnabled,
      tucanEnabled,
      toteatEnabled,
      skus: skusList,
      toteatBenefitId,
    }
    try {
      const { prize: newPrize } = await createMembershipPrizeApi(prize)
      onDone(newPrize)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo crear el premio. Intente de nuevo.')
    }
  }




  return (
    <Dialog
      title="Nuevo premio"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Crear"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onCreateMembershipPrize}
      style={{ width: '100%', }}
    >
      <Grid container spacing={3}>
        <Grid item xs={11}>
          <Typography variant="h6">Integracion con Partner</Typography>
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox onChange={e => setStoresEnabled(e.target.checked)} checked={storesEnabled} />
            }
            label="Disponible en locales"
          />
        </Grid>

        <Grid item xs={6}>
          <InputLabel className={classes.multiselectTitle}>Categoria</InputLabel>
          <FormControl className={classes.fullWidth}>
            <Select
              value={category}
              onChange={(e) => setCategory(e.target.value as PrizeCategory)}>
              {categories.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox onChange={e => setTucanEnabled(e.target.checked)} checked={tucanEnabled} />
            }
            label="Disponible en Tucan"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox onChange={e => setToteatEnabled(e.target.checked)} checked={toteatEnabled} />
            }
            label="Disponible en Toteat"
          />
        </Grid>

        {toteatEnabled && (
          <>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <TextField
                    label="SKU"
                    value={currentSku}
                    fullWidth
                    required={toteatEnabled && category === PrizeCategory.General}
                    error={errors.skusList.hasError}
                    helperText={errors.skusList.message}
                    onChange={handleCurrentSkuChange}
                    disabled={isLoading}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        handleAddSku()
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <IconButton
                    size="medium"
                    onClick={handleAddSku}
                    disabled={!currentSku.trim() || isLoading}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="ID Beneficio Toteat"
                value={toteatBenefitId}
                fullWidth
                required={toteatEnabled && category === PrizeCategory.Checkout}
                error={errors.toteatBenefitId.hasError}
                helperText={errors.toteatBenefitId.message}
                onChange={handleToteatBenefitIdChange}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <List>
                {skusList.map((sku, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={sku} />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => handleRemoveSku(sku)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12}>
          <InputLabel id="demo-simple-select-label">Tipo de premio</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={rewardType}
            onChange={handlerewardTypeSelect}
            fullWidth
          >
            {REWARD_TYPE_BY_MEMBERSHIP.map(value => (
              <MenuItem value={value.id} key={value.id}>
                {value.description}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <EditorInput
            value={title}
            label="Título premio"
            readOnly={isLoading}
            required={true}
            onChange={setTitle}
            maxLength={70}
            errorText={rules.title[0].message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Descripcion premio"
            value={shortTitle}
            fullWidth
            onChange={e => setShortTitle(e.target.value)}
            disabled={isLoading}
            required
            error={errors.shortTitle.hasError}
            helperText={errors.shortTitle.message}
            inputProps={{ maxLength: 22 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Texto adicional premio"
            value={additionalText}
            fullWidth
            onChange={e => setAdditionalText(e.target.value)}
            disabled={isLoading}
            required
            error={errors.additionalText.hasError}
            helperText={errors.additionalText.message}
          />
        </Grid>

        {rewardType === PRIZE.REWARD_TYPE.AUTOMATIC && (
        <>
            <Grid item xs={12} sm={6}>
            <TextField
              value={expirationDays}
              type='number'
              fullWidth
              onChange={e => setExpirationDays(e.target.value)}
              disabled={isLoading}
              label="Vencimiento"
              required
              InputProps={{
                inputProps: { min: 1 },
                endAdornment: <InputAdornment position="start">días</InputAdornment>,
              }}
              error={errors.expirationDays.hasError}
              helperText={errors.expirationDays.message}
            />
          </Grid>
        </>)}

        <Grid item xs={12}>
          <div className={classes.iconUploadContainer}>
              <UploadButton
                id="manualReward-icon-upload-button"
                accept=".jpg, .jpeg, .png"
                label="Subir ícono premio"
                onChange={handleIconChange}
              />
          </div>
        </Grid>
        {iconPreview && (
        <>
            <Grid item xs={12}>
            <div className={classes.iconUploadPreviewContainer}>
              <img src={iconPreview} alt="" className={classes.iconPreview} />
            </div>
          </Grid>
        </>)}
      </Grid>
    </Dialog>
  )
}

export { CreatePrizeDialog }
