import React from 'react';
import Grid from '@material-ui/core/Grid';
import { DatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import { STRINGS } from 'consts';

interface DateRangeFilterProps {
  startDate: Moment | null;
  setStartDate: (date: Moment | null) => void;
  endDate: Moment | null;
  setEndDate: (date: Moment | null) => void;
  dateFormat: string;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dateFormat,
}) => {
  const handleStartDateChange = (newDate: Moment | null) => {
    if (newDate) {
      setStartDate(newDate.startOf('day'));

      if (endDate && (newDate.isSameOrAfter(endDate))) {
        setEndDate(newDate.clone().add(1, 'day').endOf('day'));
      }
    }
  };

  const handleEndDateChange = (newDate: Moment | null) => {
    if (newDate) {
      setEndDate(newDate.endOf('day'));
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3}>
        <DatePicker
          value={startDate}
          defaultValue={null}
          onChange={handleStartDateChange}
          autoOk
          orientation="landscape"
          format={STRINGS.DATE_FORMAT}
          initialFocusedDate={moment()}
          style={{ width: '100%' }}
          label="Desde"
          maxDate={moment().endOf('day')}
          maxDateMessage="La fecha no debe ser posterior a la fecha máxima"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <DatePicker
          value={endDate}
          defaultValue={null}
          onChange={handleEndDateChange}
          autoOk
          orientation="landscape"
          format={STRINGS.DATE_FORMAT}
          initialFocusedDate={startDate || moment()}
          style={{ width: '100%' }}
          label="Hasta"
          minDate={startDate || undefined} // Restringir fechas anteriores a "Desde"
          maxDate={moment().endOf('day')}
          minDateMessage="La fecha no debe ser anterior a la fecha inicial"
          maxDateMessage="La fecha no debe ser posterior a la fecha máxima"
        />
      </Grid>
    </Grid>
  );
};

export default DateRangeFilter;
