import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import { InputLabel, LinearProgress } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { DateTimePicker } from '@material-ui/pickers'

import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles'

import { Dialog, snackbar } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { ERRORS, STRINGS } from 'consts'
import { Moment } from 'moment'

import { createNotification, fetchPushUserCount } from '../api'
import { CreateNotificationPayload, CreateNotificationResponse, DataParams, DataResponse, Notification } from '../types'

import { MultiSelect } from 'react-multi-select-component'
import { Tag } from 'features/Main/Tags/types'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import VisitsFilter from './filters/VisitsFilter'
import DateRangeFilter from './filters/DateRangeFilter'
import RewardFilter from './filters/RewardFilter'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 50,
      message: 'No puede superar los 50 caracteres',
    },
  ],
  body: [
    {
      validate: (body: string) => body.length <= 192,
      message: 'No puede superar los 192 caracteres',
    },
  ],
  scheduled: [
    {
      validate: (scheduled: Moment | null) => scheduled !== null,
      message: 'Este campo es requerido',
    },
  ],
  visitsFilter: [
    {
      validate: (visitsFilter: string | null) => visitsFilter == null ? true : parseInt(visitsFilter) <= 1000,
      message: 'Máximo permitido: 1000',
    },
  ],
}

interface CreateNotificationsDialogProps {
  handleClose: () => void
  onDone: (newNotification: Notification) => void
  tags: Tag[]
  calcUsersByTags: (tagsSelected: Tag[], allTagsAreRequired?: boolean) => Promise<number>
}

const CreateNotificationDialog: React.FC<CreateNotificationsDialogProps> = ({
  handleClose,
  onDone,
  tags,
  calcUsersByTags,
}) => {
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [scheduled, setScheduled] = useState<Moment | null>(null)
  const [tagsSelected, setTagsSelected] = useState<any[]>([])
  const [usersByTags, setUsersByTags] = useState(0)
  const [visitsFilter, setVisitsFilter] = useState<string | null>(null)
  const [visitsOperator, setVisitsOperator] = useState<'==' | '>' | '<'>('==')
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const [rewardId, setRewardId] = useState<string>('')
  const [invertedLogic, setInvertedLogic] = useState<boolean>(false)
  const [userCount, setUserCount] = useState<number | null>(null);


  const data = { title, body, scheduled, visitsFilter }
  const requiredFields = ['title', 'body', 'scheduled']
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(data, rules, requiredFields)
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors

  const classes = useStyles()
  const theme = useTheme()
  const [personName, setPersonName] = React.useState<string[]>([])

  const [allTagsAreRequired, setAllTagsAreRequired] = useState(false)

  const tagsForSelect = tags.map(el => ({ label: el.title, value: el.id }))

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPersonName(event.target.value as string[])
  }

  const [createNotificationApi, isLoading] = useApiCall<
    CreateNotificationPayload,
    CreateNotificationResponse
  >(createNotification)

  const handleSetTags = async (e: any[]) => {
    setTagsSelected(e)
  }

  useEffect(() => {
    calcUsersByTags(tagsSelected, allTagsAreRequired).then(el => setUsersByTags(el))
  }, [tagsSelected])

  const handleSubmit = async () => {
    if (!scheduled) return
    const tagsSelectedId = tagsSelected.map((tag: { value: number }) => tag.value)
    const data = {
      title,
      body,
      scheduled: scheduled.toISOString(),
      tagIds: tagsSelectedId,
      allTagsAreRequired,
      ...(visitsFilter !== null ? { visitsValue: parseInt(visitsFilter) } : {}),
      visitsOperator,
      ...(startDate?.toISOString() ? { dateFrom: startDate?.toISOString() } : {}),
      ...(endDate?.toISOString() ? { dateTo: endDate?.toISOString() } : {}),
      ...(rewardId ? { rewardId: rewardId } : {}),
      ...(invertedLogic ? { rewardInverted: invertedLogic } : {}),
    }
    try {
      const { notification: newNotification } = await createNotificationApi(data)
      onDone(newNotification)
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  const handleDateChange = (newDate: Moment | null) => {
    setScheduled(newDate)
  }

  const handleAllTagsAreRequiredChange: React.ChangeEventHandler<HTMLInputElement> = async e => {
    const total = await calcUsersByTags(tagsSelected, !allTagsAreRequired)
    await setAllTagsAreRequired(!allTagsAreRequired)
    setUsersByTags(total)
  }

  const [requestData, isRequestingData] = useApiCall<DataParams, DataResponse>(fetchPushUserCount)

  const handleFetchPushUserCount = async () => {
    try {
      const tagsSelectedId = tagsSelected.map((tag: { value: number }) => tag.value)

      const data = await requestData({
        visitsValue: visitsFilter !== null && visitsFilter.trim().length ? parseInt(visitsFilter) : undefined,
        visitsOperator,
        dateFrom: startDate?.toISOString() || undefined,
        dateTo: endDate?.toISOString() || undefined,
        rewardId: rewardId ? parseInt(rewardId) : undefined,
        rewardInverted: invertedLogic,
        tagIds: tagsSelectedId,
        allTagsAreRequired,
      })

      setUserCount(data.count)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo calcular la cantidad de usuarios. Intente de nuevo.')
    }
  }

  useEffect(() => {
    handleFetchPushUserCount()
  }, [visitsFilter, visitsOperator, startDate, endDate, rewardId, invertedLogic, tagsSelected, allTagsAreRequired])

  return (
    <Dialog
      title="Nueva notificación"
      isOpen
      onCancel={handleClose}
      showActions
      okButtonText="Crear notificación"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={handleSubmit}
      isLoading={isLoading}
      contentStyle={{ minWidth: 500 }}
      //   overflow
      style={{ minHeight: '40%' }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Título"
            required
            onChange={e => setTitle(e.target.value)}
            value={title}
            error={errors.title.hasError}
            helperText={errors.title.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Mensaje"
            required
            onChange={e => setBody(e.target.value)}
            value={body}
            multiline
            error={errors.body.hasError}
            helperText={errors.body.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            value={scheduled}
            onChange={handleDateChange}
            autoOk
            disablePast
            format={STRINGS.DATE_TIME_FORMAT}
            initialFocusedDate={new Date()}
            disabled={isLoading}
            style={{ width: '100%' }}
            label="Programado"
            required
          />
        </Grid>
        <Grid item xs={11}>
          <InputLabel>Tags</InputLabel>
          <MultiSelect
            options={tagsForSelect}
            value={tagsSelected}
            onChange={handleSetTags}
            labelledBy={'Select'}
            hasSelectAll={false}
          />
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel
            control={
              <Checkbox onChange={handleAllTagsAreRequiredChange} checked={allTagsAreRequired} />
            }
            label="Sólo usuarios con todos los tags seleccionados"
          />
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined" square style={{ padding: 20 }} >
            <Grid item xs={11}>
              <VisitsFilter
                visitsFilter={visitsFilter}
                setVisitsFilter={setVisitsFilter}
                visitsOperator={visitsOperator}
                setVisitsOperator={setVisitsOperator}
                error={errors.visitsFilter.hasError}
                helperText={errors.visitsFilter.message}
              />
            </Grid>
            <Grid item xs={11}>
              <DateRangeFilter
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                dateFormat={STRINGS.DATE_FORMAT}
              />
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={11}>
          <RewardFilter
            rewardId={rewardId}
            setRewardId={setRewardId}
            invertedLogic={invertedLogic}
            setInvertedLogic={setInvertedLogic}
          />
        </Grid>
        <Grid item xs={11} >
          <InputLabel>
            {isRequestingData ? (
              <LinearProgress />
            ) : (
              `Esta acción impactará a : ${userCount ?? 'N/A'} usuarios`
            )}
          </InputLabel>
        </Grid>
      </Grid>
    </Dialog>
  )
}

const names = ['TAG-1', 'TAG-2', 'TAG-3', 'TAG-4', 'TAG-5']

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    fullWidth: {
      width: '100%',
    },
  }),
)

export { CreateNotificationDialog }
