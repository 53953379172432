import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { Dialog, snackbar, UploadButton } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { ERRORS, PRIZE } from 'consts'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

import { updateNotification } from '../api'
import { UpdateNotificationData, Notification, UpdateNotificationResponse } from '../types'
import { resizeFile } from 'utils';
import { useStyles } from './styles'
import { Divider, FormControl, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Select, Typography } from '@material-ui/core'
import { PrizeCategory } from 'consts/prize'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 50,
      message: 'No puede superar los 50 caracteres',
    },
  ],
  body: [
    {
      validate: (body: string) => body.length <= 192,
      message: 'No puede superar los 192 caracteres',
    },
  ],
  prizeTitle: [
    {
      validate: (title: string) => title.length <= 25,
      message: 'No puede superar los 25 caracteres',
    },
  ],
  prizeShortTitle: [
    {
      validate: (prizeShortTitle: string) => prizeShortTitle.length <= 22,
      message: 'No puede superar los 22 caracteres',
    },
  ],
  additionalText: [
    {
      validate: (value: string) => (value && value.length <= 100) || !value.length,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  description: [
    {
      validate: (description: string) => description.length <= 1024,
      message: 'No puede superar los 1024 caracteres',
    },
  ],
  expirationDays: [
    {
      validate: (value: string) =>
        ((value && Number(value) >= 1) || !value) && Number.isInteger(Number(value)),
      message: 'El vencimiento debe ser un número entero mayor a 0',
    },
  ],
  toteatBenefitId: [
    {
      validate: (value: string, data: any) => {
        const { toteatEnabled, category } = data
        if (toteatEnabled && category === PrizeCategory.Checkout) {
          return value.trim().length > 0
        }
        return true
      },
      message: 'El ID Beneficio Toteat es requerido',
      validateEmpty: true
    }
  ],
  skusList: [
    {
      validate: (skusList: string[], data: any) => {
        const { toteatEnabled, category } = data
        if (toteatEnabled && category === PrizeCategory.General) {
          return skusList.length > 0
        }
        return true
      },
      message: 'El SKU es requerido',
    }
  ]
}

interface UpdateNotificationDialogProps {
  notification: Notification
  handleClose: () => void
  onDone: (newNotification: Notification) => void
}

const categories = Object.values(PrizeCategory)

const UpdateNotificationDialog: React.FC<UpdateNotificationDialogProps> = ({ notification, handleClose, onDone }) => {
  const [toteatBenefitId, setToteatBenefitId] = useState<string>(notification.prize.toteatBenefitId || '')
  const [currentSku, setCurrentSku] = useState<string>('')
  const [skusList, setSkusList] = useState<string[]>(notification.prize.skus ? notification.prize.skus.map(s => s.sku) : [])
  const [storesEnabled, setStoresEnabled] = useState(notification.prize.storesEnabled)
  const [tucanEnabled, setTucanEnabled] = useState(notification.prize.tucanEnabled)
  const [toteatEnabled, setToteatEnabled] = useState(notification.prize.toteatEnabled)
  const [category, setCategory] = useState<PrizeCategory | undefined>(notification.prize.category)
  const [title, setTitle] = useState(notification.title)
  const [body, setBody] = useState(notification.body)
  const [icon, setIcon] = useState<File | undefined>(undefined)
  const [iconPreview, setIconPreview] = useState(notification.prize.iconUrl)
  const [prizeTitle, setPrizeTitle] = useState(notification.prize.title)
  const [prizeShortTitle, setPrizeShortTitle] = useState(notification.prize.shortTitle)
  const [expirationDays, setExpirationDays] = useState<string>(notification.prize.expirationDays ? notification.prize.expirationDays.toString() : '30')
  const [additionalText, setAdditionalText] = useState(notification.prize.additionalText ? notification.prize.additionalText : '')
  const [activePrize, setActivePrize] = useState(notification.activePrize)
  const [active, setActive] = useState(notification.active)
  const [description, setDescription] = useState(notification.description)

  const getRequiredFields = () => {
    const baseRequiredFields = ['title', 'body', 'description']
    const prizeRequiredFields = ['prizeTitle', 'prizeShortTitle', 'additionalText', 'expirationDays']

    if (activePrize) {
      baseRequiredFields.push(...prizeRequiredFields)
    }

    if (toteatEnabled) {
      if (category === PrizeCategory.General) {
        baseRequiredFields.push('skusList')
      } else if (category === PrizeCategory.Checkout) {
        baseRequiredFields.push('toteatBenefitId')
      }
    }

    return baseRequiredFields
  }

  const data = { title, body, description, prizeTitle, prizeShortTitle, additionalText, expirationDays, activePrize, toteatEnabled, toteatBenefitId, skusList, category }
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(data, rules, getRequiredFields())
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors

  const [updateNotificationApi, isLoading] = useApiCall<UpdateNotificationData, UpdateNotificationResponse>(updateNotification)

  const handleSubmit = async () => {
    if (activePrize && !iconPreview) {
      snackbar.show('Para activar el premio es necesario cargar el icono')
      return
    }

    const data = {
      title,
      body,
      icon,
      active,
      activePrize,
      description,
      prize: {
        title: prizeTitle,
        shortTitle: prizeShortTitle,
        additionalText,
        expirationDays,
        category,
        storesEnabled,
        tucanEnabled,
        toteatEnabled,
        skus: skusList,
        toteatBenefitId,
      }
    }

    try {
      const { notification: updatedNotification } = await updateNotificationApi({ id: notification.id, payload: data })
      onDone(updatedNotification)
    } catch (err) {
      let errorMessage = err.message ?? ERRORS.GENERIC_ERROR_MESSAGE
      snackbar.show(errorMessage)
    }
  }

  const handleIconChange = async (file: File) => {
    setIconPreview(URL.createObjectURL(file))
    const image = await resizeFile(file, 192, 192)
    setIcon(image)
  }

  const handleChangeActivePrize: React.ChangeEventHandler<HTMLInputElement> = e => {
    setActivePrize(!activePrize)
  }

  const handleChangeActive: React.ChangeEventHandler<HTMLInputElement> = e => {
    setActive(!active)
  }

  const handleToteatBenefitIdChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setToteatBenefitId(e.target.value)
  }

  const handleCurrentSkuChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setCurrentSku(e.target.value)
  }

  const handleAddSku = () => {
    if (currentSku.trim()) {
      setSkusList([...skusList, currentSku.trim()])
      setCurrentSku('')
    }
  }

  const handleRemoveSku = (skuToRemove: string) => {
    setSkusList(skusList.filter(sku => sku !== skuToRemove))
  }

  const classes = useStyles()
  return (
    <Dialog
      title={`Editar notificación`}
      isOpen
      onCancel={handleClose}
      showActions
      okButtonText="Editar notificación"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={handleSubmit}
      isLoading={isLoading}
      contentStyle={{ minWidth: 500 }}
      style={{ minHeight: '40%' }}
    >
      <Grid container spacing={3}>
        <Grid item xs={11}>
          <FormControlLabel control={<Checkbox onChange={handleChangeActive} checked={active} />} label="Activar notificación" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Descripción Push Automática"
            required
            onChange={e => setDescription(e.target.value)}
            value={description}
            error={errors.body.hasError}
            helperText={errors.description.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Título"
            required
            onChange={e => setTitle(e.target.value)}
            value={title}
            error={errors.title.hasError}
            helperText={errors.title.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Mensaje"
            required
            multiline
            onChange={e => setBody(e.target.value)}
            value={body}
            error={errors.body.hasError}
            helperText={errors.body.message}
          />
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel control={<Checkbox onChange={handleChangeActivePrize} checked={activePrize} />} label="Activar premio" />
        </Grid>
        {activePrize && (
          <>
            <Grid item xs={11}>
              <Typography variant="h6">Integracion con Partner</Typography>
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox onChange={e => setStoresEnabled(e.target.checked)} checked={storesEnabled} />
                }
                label="Disponible en locales"
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel className={classes.multiselectTitle}>Categoria</InputLabel>
              <FormControl className={classes.fullWidth}>
                <Select
                  value={category}
                  onChange={(e) => setCategory(e.target.value as PrizeCategory)}>
                  {categories.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox onChange={e => setTucanEnabled(e.target.checked)} checked={tucanEnabled} />
                }
                label="Disponible en Tucan"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox onChange={e => setToteatEnabled(e.target.checked)} checked={toteatEnabled} />
                }
                label="Disponible en Toteat"
              />
            </Grid>

            {toteatEnabled && (
              <>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={9}>
                      <TextField
                        label="SKU"
                        value={currentSku}
                        fullWidth
                        required={toteatEnabled && category === PrizeCategory.General}
                        error={errors.skusList.hasError}
                        helperText={errors.skusList.message}
                        onChange={handleCurrentSkuChange}
                        disabled={isLoading}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            handleAddSku()
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton
                        size="medium"
                        onClick={handleAddSku}
                        disabled={!currentSku.trim() || isLoading}
                      >
                        <AddIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="ID Beneficio Toteat"
                    value={toteatBenefitId}
                    fullWidth
                    required={toteatEnabled && category === PrizeCategory.Checkout}
                    error={errors.toteatBenefitId.hasError}
                    helperText={errors.toteatBenefitId.message}
                    onChange={handleToteatBenefitIdChange}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={6}>
                  <List>
                    {skusList.map((sku, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={sku} />
                        <ListItemSecondaryAction>
                          <IconButton onClick={() => handleRemoveSku(sku)}>
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>
          </>
        )}
        {activePrize && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Título"
              required
              onChange={e => setPrizeTitle(e.target.value)}
              value={prizeTitle}
              error={errors.prizeTitle.hasError}
              helperText={errors.prizeTitle.message}
            />
          </Grid>
        )}
        {activePrize && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Descripción"
              required
              onChange={e => setPrizeShortTitle(e.target.value)}
              value={prizeShortTitle}
              error={errors.prizeShortTitle.hasError}
              helperText={errors.prizeShortTitle.message}
            />
          </Grid>
        )}
        {activePrize && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Descripción Adicional"
              required
              onChange={e => setAdditionalText(e.target.value)}
              value={additionalText}
              error={errors.additionalText.hasError}
              helperText={errors.additionalText.message}
            />
          </Grid>
        )}
        {(activePrize && notification.prize.rewardType === PRIZE.REWARD_TYPE.AUTOMATIC) && (
        <>
          <Grid item xs={11} sm={5}>
            <TextField
              value={expirationDays}
              type='number'
              fullWidth
              onChange={e => setExpirationDays(e.target.value)}
              disabled={isLoading}
              label="Vencimiento"
              required
              InputProps={{
                inputProps: { min: 1 },
                endAdornment: <InputAdornment position="start">días</InputAdornment>,
              }}
              error={errors.expirationDays.hasError}
              helperText={errors.expirationDays.message}
            />
          </Grid>
        </>)}
        {activePrize && (
          <>
            <Grid item xs={11}>
              <div className={classes.iconUploadContainer}>
                <UploadButton
                  id="manualReward-icon-upload-button"
                  accept=".jpg, .jpeg, .png"
                  label="Subir ícono"
                  onChange={handleIconChange}
                />
              </div>
            </Grid>

            <Grid item xs={11}>
              <div className={classes.iconUploadPreviewContainer}>
                <img src={iconPreview} alt="" className={classes.iconPreview} />
              </div>
            </Grid>
          </>)}
      </Grid>
    </Dialog>
  )
}

export { UpdateNotificationDialog }
