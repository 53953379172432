import React, { useState } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'

import { STRINGS } from 'consts'
import { useApiCall } from 'hooks'
import { GenderEnum } from 'types'
import { COLORS } from 'styles'

import { buildPaginationQuery, DefaultTable } from '../components'
import { getUsersDto } from './api'
import { GetUsersPaginatedResponse, User } from './types'
import { PollDialog } from './components'
import { Query, QueryResult } from 'material-table'

const useStyles = makeStyles({
  link: { color: COLORS.ACCENT },
})

const GenderMap = {
  [GenderEnum.Female]: 'Mujer',
  [GenderEnum.Male]: 'Hombre',
  [GenderEnum.Other]: 'Otro',
  [GenderEnum.NoAnswer]: 'Prefiero no responder',
}

const Users: React.FC = () => {
  const [getUsersApi, isLoading] = useApiCall<string, GetUsersPaginatedResponse>(getUsersDto)
  const fetchUsers = async (query: Query<User>): Promise<QueryResult<User>> => getUsersApi(buildPaginationQuery(query))

  const classes = useStyles()

  const [isPollModalOpen, setIsPollModalOpen] = useState(false)
  const [currentUser, setCurrentUserId] = useState<User|null>(null)
  const [pageSize, setPageSize] = useState(10)

  const handleViewPoll = (e: React.SyntheticEvent, user: User) => {
    e.preventDefault()
    setIsPollModalOpen(true)
    setCurrentUserId(user)
  }

  const handlePollModalClose = () => {
    setIsPollModalOpen(false)
    setCurrentUserId(null)
  }

  const columns = [
    { title: 'ID', field: 'id' },
    { title: 'Email', field: 'email' },
    { title: 'Nombre', field: 'fullname' },
    {
      title: 'Cumpleaños',
      field: 'birthdate',
      render: (rowData: User) =>
        rowData.birthdate
          ? moment(rowData.birthdate).format(STRINGS.DATE_FORMAT)
          : STRINGS.TABLE_COLUMN_NOT_DEFINED,
    },
    {
      title: 'Sexo',
      field: 'gender',
      render: (rowData: User) =>
        rowData.gender ? GenderMap[rowData.gender] : STRINGS.TABLE_COLUMN_NOT_DEFINED,
    },
    { title: 'Código QR', field: 'qrHash', sorting: false },
    {
      title: 'Premios obtenidos',
      field: 'obtainedPrizes',
      sorting: false,
      render: (rowData: User) => rowData.obtainedPrizes || STRINGS.TABLE_COLUMN_NOT_DEFINED,
    },
    {
      title: 'Premios canjeados',
      field: 'redeemedPrizes',
      sorting: false,
      render: (rowData: User) => rowData.redeemedPrizes || STRINGS.TABLE_COLUMN_NOT_DEFINED,
    },
    {
      title: 'User Test',
      render: (row: User) => (
        <a href="/" className={classes.link} onClick={e => handleViewPoll(e, row)}>
          Ver
        </a>
      ),
      sorting: false,
    },
  ]

  return (
    <>
      <DefaultTable
        title="Usuarios"
        columns={columns}
        data={fetchUsers}
        isLoading={isLoading}
        onChangeRowsPerPage={(pageSize) => { setPageSize(pageSize) }}
        options={{ debounceInterval: 700, pageSize }}
      />
      {isPollModalOpen && currentUser && (
        <PollDialog data={currentUser} onCancel={handlePollModalClose} />
      )}
    </>
  )
}

export { Users }
