// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import ToggleOnIcon from '@material-ui/icons/ToggleOn'
import ToggleOffIcon from '@material-ui/icons/ToggleOff'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { Dialog } from 'components'
import { useApiCall } from 'hooks'

import { DrawerItem, UpdateDrawerItemEnabledPayload } from '../types'
import { updateDrawerItemsEnabled } from '../api'

interface Props {
  drawerItems: DrawerItem[]
  handleClose: () => void
  onDone: (newData: DrawerItem[]) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  enabledIcon: {
    color: theme.palette.success.main,
  },
}))

const EnableDrawerItemsDialog: React.FC<Props> = ({ drawerItems, handleClose, onDone }) => {
  const classes = useStyles()

  const [drawerItemsEnabled, setDrawerItemsEnabled] = useState<DrawerItem[]>(drawerItems)
  const [updateEnabledApi, isLoading] = useApiCall<UpdateDrawerItemEnabledPayload[], void>(
    updateDrawerItemsEnabled,
  )

  const onSave = async () => {
    try {
      await updateEnabledApi(
        drawerItemsEnabled.map(item => ({ id: item.id, enabled: item.enabled })),
      )
      onDone(drawerItemsEnabled)
    } catch (err) {
      // TODO: handle properly
      console.error(err)
    }
  }

  const handleEnable = (item: DrawerItem) => {
    setDrawerItemsEnabled(prevItems =>
      prevItems.map(prevItem =>
        prevItem.id === item.id ? { ...prevItem, enabled: !prevItem.enabled } : prevItem,
      ),
    )
  }

  return (
    <Dialog
      title="Habilitar/deshabilitar links"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Guardar"
      onAccept={onSave}
      contentStyle={{ minWidth: 500 }}
    >
      {drawerItemsEnabled.map(item => (
        <List key={item.id} dense>
          <ListItem>
            <ListItemText primary={item.name} />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => handleEnable(item)}>
                {item.enabled ? (
                  <ToggleOnIcon className={classes.enabledIcon} />
                ) : (
                  <ToggleOffIcon />
                )}
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      ))}
    </Dialog>
  )
}

export { EnableDrawerItemsDialog }
